<template>
    <div class="main-container">
      <h5 class="pageTitle">발송 결과 조회</h5>
      <mdb-container class="search-container">
        <mdb-row class="search-row">
          <mdb-col class="search-col">
            <!-- 검색 기준 선택 박스 -->
            <select v-model="searchCriteria" @change="updatePlaceholder" class="search-select">
              <option value="등록일시">등록일시</option>
              <option value="발송일시">발송일시</option>
              <option value="발신번호">발신번호</option>
              <option value="수신번호">수신번호</option>
            </select>
            <input
              type="text"
              v-model="search"
              :placeholder="placeholder"
              @input="filterResults"
              class="search-input"
            />
            <button class="custom-btn-box btn-search" @click="searchButton">검색</button>
            <button class="custom-btn-box btn-register" @click="goToRegister">등록</button>
            <button class="custom-btn-box btn-refresh" @click="refreshData">갱신</button>
          </mdb-col>
        </mdb-row>
      </mdb-container>
  
      <!-- 결과 테이블 -->
      <table>
        <thead>
          <tr>
            <th>NO.</th>
            <th>등록일시</th>
            <th>발송일시</th>
            <th>발신번호</th>
            <th>수신번호</th>
            <th>내용</th>
            <th>결과</th>
            <th>에러코드</th>
            <th>코드설명</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedData.length === 0">
            <td colspan="9">데이터가 없습니다.</td>
          </tr>
          <tr
            v-for="(result, index) in paginatedData"
            :key="index"
            :class="{ 'alt-row': index % 2 === 1  ,disabled: result.status === '완료'}"
             @click="goToEdit(result)" 
           
          >
            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
            <td>{{ result.requestTime }}</td>
            <td>{{ result.sendTime }}</td>
            <td>{{ result.senderPhone }}</td>
            <td>{{ result.receiverPhone }}</td>
            <td>{{ result.messageBody }}</td>
            <td>{{ result.status }}</td>
            <td>{{ result.errorCode }}</td>
            <td>{{ result.errorDescription }}</td>
          </tr>
        </tbody>
      </table>
  
      <!-- 페이지네이션 -->
      <div class="pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>Page {{ currentPage }} of {{ pageCount }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === pageCount">Next</button>
      </div>
    </div>
  </template>
  
  
  
  <script>
import { mdbCol, mdbContainer, mdbRow } from "mdbvue";
import axios from "@/axios";
import Swal from 'sweetalert2';

export default {
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
  },
  data() {
    return {
      searchCriteria: "등록일시", // 기본 검색 기준
      placeholder: "검색할 등록일시를 입력하세요.",
      search: "",
      results: [], // 서버에서 가져온 원본 데이터
      filteredResults: [], // 검색된 결과 데이터
      currentPage: 1,
      perPage: 10,
      errorDescription:{
        "0000":"-",
        "4100":"전달",
        "4400":"음영 지역",
        "4401":"단말기 전원 꺼짐",
        "4402":"단말기 메시지 저장 초과",
        "4403":"메시지 삭제 됨",
        "4404":"가입자 위치 정보 없음",
        "4405":"단말기 BUSY",
        "4410":"잘못된 번호",
        "4420":"기타에러",
        "4430":"스팸",
        "4431":"발송 제한 수신거부(스팸)",
        "4411":"NPDB 에러",
        "4412":"착신거절",
        "4413":"SMSC 형식오류",
        "4414":"비가입자, 결번, 서비스정지",
        "4415":"식별코드 오류",
        "4421":"타임아웃",
        "4422":"단말기 일시정지",
        "4423":"단말기 착신거부",
        "4424":"URL SMS 미지원폰",
        "4425":"단말기 호 처리 중",
        "4426":"재시도 한도 초과",
        "4427":"기타 단말기 문제",
        "4428":"시스템 에러",
        "4432":"회신번호 차단(개인)",
        "4433":"회신번호 차단(기업)",
        "4434":"회신번호 사전 등록제에 의한 미등록 차단",
        "4435":"KISA 신고 스팸 회신 번호 차단",
        "4436":"회신번호 사전 등록제 번호규칙 위반",
        "4437":"중복 발신 제한",
        "4438":"화이트리스트 문구 체크",
        "4443":"KISA 스팸 차단",
        "9903":"선불사용자 사용금지",
        "9904":"Block time (날짜제한)",
        "9081":"선불 사용자 FAX, PHONE 발송 제한",
        "9082":"발송해제",
        "9083":"IP 차단",
        "9084":"DEVICE 발송 제한",
        "9085":"사용 금지 Callback 번호",
        "9905":"Block time",
        "9907":"지원하지 않는 메시지 타입",
        "9010":"아이디 틀림",
        "9011":"비밀번호 틀림",
        "9012":"중복 접속량 많음",
        "9013":"패스워드 sha-256 암호화 미설정",
        "9014":"알림톡/친구톡 유효하지 않은 발신 프로필키",
        "9015":"알림톡/친구톡 발신 프로필키 미 입력",
        "9016":"알림톡/친구톡 템플릿 미 입력",
        "9017":"존재하지 않는 첨부파일",
        "9018":"0 바이트 첨부파일",
        "9019":"지원하지 않는 첨부파일",
        "9020":"Wrong Data Format or SEND_TIME 기준 24 시간이 경과한 메시지",
        "9022":"Wrong Data Format (ex. cinfo 가 특수 문자 / , 공백 을 포함)",
        "9023":"시간제한 (리포트 수신대기 timeout)",
        "9024":"Wrong Data Format (ex. 메시지 본문 길이)",
        "9026":"블랙리스트에 의한 차단",
        "9027":"MMS 첨부파일 이미지 사이즈 초과",
        "9028":"첨부파일 URL 구문 오류",
        "9029":"JSON String 구문 오류",
        "9030":"지원하지 않는 첨부파일 데이터 타입",
        "9031":"첨부파일 테이블과 매칭되는 MSG_KEY 가 없음",
        "9032":"RCS 테이블과 매칭되는 REFKEY 가 없음",
        "9033":"지원하지 않는 대체발송 타입",
        "9034":"발송 유효시간 만료",
        "9035":"Url 다운로드 실패",
        "9036":"브랜드톡 변수형 필수 파라미터 불일치",
        "9037":"시간제한 (클라이언트 timeout)",
        "9070":"선불 잔액 부족",
        "9071":"후불 한도 초과",
        "9072":"정의되지 않은 단가",
        "9074":"등록되지 않는 MessagebaseID or ChatbotID",
        "9080":"Deny User Ack",
        "9214":"Wrong Phone Num",
        "9311":"Uploaded File Not Found",
        "9908":"PHONE, FAX 선불사용자 제한기능",
        "9090":"기타에러",
        "9215":"잘못된 식별코드",       
      },
    };
  },
  watch: {
      search() {
        this.currentPage = 1;
      }
    },
  computed: {
    // 페이지네이션 데이터 계산
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.filteredResults.slice(start, start + this.perPage);
    },
    pageCount() {
      return Math.ceil(this.filteredResults.length / this.perPage);
    },
  },
  methods: {
    goToEdit(rowData) {
      if (rowData.status === '발송완료') {
        // 발송 완료 상태일 경우 알림을 띄움
        Swal.fire('수정 불가', '발송 완료된 메시지는 수정/삭제할 수 없습니다.', 'info');
        return;
      }
      console.log("전달 데이터(rowData):", rowData); // 디버깅용
      this.$router.push({
      name: "sms-updateDelete",
      query: {
        cmid: rowData.cmid,
        requestTime: rowData.requestTime,
        sendTime: rowData.sendTime,
        senderPhone: rowData.senderPhone,
        receiverPhone: rowData.receiverPhone,
        messageBody: rowData.messageBody,
        status: rowData.status,
        errorCode: rowData.errorCode,
      },
    });
  },
    async refreshData() {
      try {
        console.log("데이터 갱신 중...");
        await this.fetchResults(); // 기존 fetchResults 메서드를 재사용
        console.log("데이터가 성공적으로 갱신되었습니다.");
      } catch (error) {
        console.error("데이터 갱신 오류:", error);
      }
    },
    // 데이터 가져오기
    async fetchResults() {
        const tokenData = JSON.parse(sessionStorage.getItem('token'));
        const token = tokenData ? tokenData.access_token : ''; // 로컬 스토리지에서 토큰 가져오기

        if (!token) {
            Swal.fire('오류', '인증 토큰이 없습니다. 다시 로그인해주세요.', 'error');
            return;
        }

        const config = {
            method: 'get',
            url: '/sms-main/read_message', // 조회 URL
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // 인증 토큰 추가
            },
        };

        try {
            const response = await axios(config);
            // 백엔드에서 받은 데이터를 프론트엔드에서 사용할 형태로 매핑
            this.results = response.data.map((item) => ({
                cmid: item.cmid,
                requestTime: this.formatDate(item.request_time), // 요청 시간
                sendTime: this.formatDate(item.send_time), // 발송 시간
                senderPhone: item.send_phone, // 발신자 번호
                receiverPhone: item.dest_phone, // 수신자 번호
                messageBody: item.msg_body, // 메시지 내용
                status: this.getStatus(item.status), // 메시지 상태 변환
                errorCode: item.call_status || '-', // 에러 코드
                errorDescription: this.errorDescription[item.call_status] || "알 수 없는 코드",
            }));
            this.filteredResults = this.results; // 초기 필터링 데이터 설정
        } catch (error) {
            Swal.fire('오류', '메시지 조회 중 오류가 발생했습니다.', 'error');
            console.error('메시지 조회 오류:', error);
        }
    },

    // 상태 변환
    getStatus(status) {
      const statuses = { 0: "대기", 1: "발송 후 대기", 2: "발송완료", 7:"발송 중", 3:"리포트 재요청 후 대기",11:"대체발송중",12:"대체발송완료"  };
      return statuses[status] || "알 수 없음";
    },

    // 날짜 형식 변환
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString(); // 예: "2024-09-01 12:00"
    },

    // 검색 기준 변경 시 placeholder 업데이트
    updatePlaceholder() {
      const placeholders = {
        "등록일시": "검색할 등록일시를 입력하세요.",
        "발송일시": "검색할 발송일시를 입력하세요.",
        "발신번호": "검색할 발신번호를 입력하세요.",
        "수신번호": "검색할 수신번호를 입력하세요.",
      };
      this.placeholder = placeholders[this.searchCriteria];
    },

    // 검색 필터
    filterResults() {
      const criteriaMapping = {
        "등록일시": "requestTime",
        "발송일시": "sendTime",
        "발신번호": "senderPhone",
        "수신번호": "receiverPhone",
      };

      const criteria = criteriaMapping[this.searchCriteria];
      const search = this.search.trim().toLowerCase();

      if (!search) {
        this.filteredResults = this.results;
        return;
      } 
      if (criteria === "requestTime" || criteria === "sendTime") {
      // 날짜/시간 필드 검색
        this.filteredResults = this.results.filter((result) => {
          const dateString = result[criteria].trim(); // 예: "2024. 11. 15. 오후 3:34:00"

          //console.log("데이터 비교값:", dateString); // 디버깅용

          // 검색어와 데이터 문자열을 정규화 (공백 제거)
          const normalizedData = dateString.replace(/\s/g, "").toLowerCase();
          const normalizedSearch = search.replace(/\s/g, "").toLowerCase();

          // 검색어가 데이터에 포함되는지 확인
          const isMatch = normalizedData.includes(normalizedSearch);

          //console.log("비교 결과:", isMatch); // 디버깅용
          return isMatch;
        });
      } 
      else {
        this.filteredResults = this.results.filter((result) =>
          result[criteria].toLowerCase().includes(search)
        );
      }
    },

    // 검색 버튼 클릭 시
    searchButton() {
      this.currentPage = 1;
      this.filterResults();
    },

    // 이전 페이지로 이동
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },

    // 다음 페이지로 이동
    nextPage() {
      if (this.currentPage < this.pageCount) this.currentPage++;
    },

    // 등록 버튼 클릭
    goToRegister() {
        this.$router.push({ name: 'sms-create' }); 
      console.log("등록 버튼 클릭!");
    },
  },
  mounted() {
    this.fetchResults();
    this.updatePlaceholder(); // 초기화 시 placeholder 설정
  },
};
</script>

  
<style scoped>
.main-container {
  padding: 15px;
}

.pageTitle {
  font-weight: bold;
 /* font-size: 24px;
   text-align: center;
  margin-bottom: 20px; */
}

.search-container {
  padding: 25px;
}

.search-row {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 오른쪽 정렬 */
}

.search-col {
  display: flex;
  align-items: center;
  gap: 10px; /* 각 요소 사이에 간격 추가 */
}

.search-select {
  height: 38px;
  border-radius: 3px;
  padding: 8px;
  border: 1px solid rgba(130, 128, 128, 0.26);
}

.search-input {
  height: 38px;
  border-radius: 3px;
  width: 100%;
  padding: 8px;
  border: 1px solid rgba(130, 128, 128, 0.26);
}

.btn-search {
  background-color: #e6f2ff;
  padding: 11px 12px;
  font-size: 14px;
  border: 0;
  width: 67px;
  height: 38px;
  line-height: 1rem;
  color: #3e3e3e;
  margin-right: 20px; /* 검색 버튼과 등록 버튼 사이의 여백 */
}

.btn-register {
  background-color: #ffc107;
  padding: 11px 12px;
  font-size: 14px;
  border: 0;
  width: 67px;
  height: 38px;
  line-height: 1rem;
  color: #3e3e3e;
}
.btn-refresh {
  background-color: #cabbf6; /* 버튼 색상 */
  color:  #3e3e3e;
  padding: 11px 12px;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  width: 67px;
  height: 38px;
  line-height: 1rem;
  margin-left: 20px; /* 검색 버튼과 간격 */
}
table {
  width: 80%;
  border-collapse: collapse;
  margin: auto;
  text-align: center;
}

th,
td {
  border: 1px solid #cbc8c8;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #e3e4eb;
  height: 55px;
}

tr {
  height: 50px;
  cursor: pointer;
}
.disabled {
  background-color: #ccc; /* 회색 배경 */
  color: #666; /* 회색 텍스트 */
  cursor: not-allowed; /* 클릭 불가 표시 */
}
.alt-row {
  background-color: #f9f9f9;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #45a049;
}

.pagination-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

tr:hover {
  background-color: #e7e3e3;
}
</style>
